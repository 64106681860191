import React  from 'react'

function CheckoutForm() {

  return (
    <form className='qrForm-url'>
      <div className='form-floating mb-3'>
      <a href="https://CheckoutQR.com/" target="_blank" rel="noreferrer">
        <button className="btn btn-dark mx-2" type="button">
          Create Checkout with CheckoutQR
        </button>
      </a>
      </div>
    </form>
  )
}

export default CheckoutForm
